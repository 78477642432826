export const get = async <T>(
  baseUrl: string,
  params: Record<string, string> = {},
  fetchParams?: RequestInit
): Promise<T> => {
  const paramsLine = Object.entries(params).reduce(
    (line, [key, value]) => `${line ? `${line}&` : `?${line}`}${key}=${value}`,
    ""
  );

  const request = await fetch(`${baseUrl}${paramsLine}`, { method: "GET" });

  if (request.status >= 200 && request.status < 300) {
    return request.json() as Promise<T>;
  }

  throw request;
};
