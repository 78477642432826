import Image from "next/image";
import { Flex } from "@common/components";
import { ImagesPaths } from "@common/configs/ImageConfig";
import { ClassTypes } from "@instructors/types";
import styles from "./InstructorCardTag.module.scss";

export type InstructorCardTagProps = {
  tagType: ClassTypes | null;
};

export const InstructorCardTag: React.FC<InstructorCardTagProps> = ({
  tagType,
}) => {
  return (
    <Flex className={styles.root}>
      {Boolean(tagType) &&
        Object.keys(ImagesPaths.tag).includes(tagType as string) && (
          <Image
            src={ImagesPaths.tag[tagType as ClassTypes]}
            alt={"tag image"}
            width={90}
            height={60}
          />
        )}
    </Flex>
  );
};
