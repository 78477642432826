import styles from "./InstructorModalCertification.module.scss";

export type InstructorModalCertificationProps = {
  certificateNumber: number | null;
  certificateDate: string | null;
  certificateExpireDate: string | null;
  company?: string | null;
  location: string;
};

export const InstructorModalCertification: React.FC<
  InstructorModalCertificationProps
> = ({
  certificateDate,
  certificateNumber,
  certificateExpireDate,
  company,
  location,
}) => {
  const ITEMS = [
    {
      placeholder: "CERTIFICATION № ",
      externalValue: certificateNumber,
    },
    {
      placeholder: "CERTIFICATED SINCE ",
      externalValue: certificateDate,
    },
    {
      placeholder: "VALID THROUGH ",
      externalValue: certificateExpireDate,
    },
    {
      placeholder: "LOCATION ",
      externalValue: location,
    },
    {
      placeholder: "COMPANY ",
      externalValue: company,
    },
  ];

  return (
    <ul className={styles.root}>
      {ITEMS.map(
        ({ placeholder, externalValue }) =>
          externalValue && (
            <li key={placeholder} className={styles.item}>
              {placeholder}{" "}
              <span className={styles.item__value}>{externalValue}</span>
            </li>
          )
      )}
    </ul>
  );
};
