import { FormLineProps } from "@common/components";

type onFieldChange = (
  name:
    | "last_name"
    | "city"
    | "state"
    | "country"
    | "zip"
    | "certificate_number",
  value: string
) => void;

export const getSearchFormFields = ({
  labelClassName,
  formLineClassName,
  onFieldChange,
}: {
  labelClassName: string;
  formLineClassName: string;
  onFieldChange: onFieldChange;
}): FormLineProps[] => [
  {
    labelOptions: { title: "LAST NAME", className: labelClassName },
    inputOptions: {
      name: "last_name",
      "aria-label": "Input field for last name (not required)",
      onChange: (e: any) => onFieldChange("last_name", e.currentTarget.value),
    },
    className: formLineClassName,
  },
  {
    labelOptions: { title: "CITY", className: labelClassName },
    inputOptions: {
      name: "city",
      "aria-label": "Input field for city (not required)",
      onChange: (e: any) => onFieldChange("city", e.currentTarget.value),
    },
    className: formLineClassName,
  },
  {
    labelOptions: { title: "STATE", className: labelClassName },
    inputOptions: {
      name: "state",
      "aria-label": "Input field for state (not required)",
      onChange: (e: any) => onFieldChange("state", e.currentTarget.value),
    },
    className: formLineClassName,
  },
  {
    labelOptions: { title: "COUNTRY", className: labelClassName },
    inputOptions: {
      name: "country",
      "aria-label": "Input field for country (not required)",
      onChange: (e: any) => onFieldChange("country", e.currentTarget.value),
    },
    className: formLineClassName,
  },
  {
    labelOptions: { title: "ZIP", className: labelClassName },
    inputOptions: {
      name: "zip",
      "aria-label": "Input field for zip number (not required)",
      onChange: (e: any) => onFieldChange("zip", e.currentTarget.value),
    },
    className: formLineClassName,
  },
  {
    labelOptions: {
      title: "Certificate №",
      className: labelClassName,
    },
    inputOptions: {
      name: "certificate_number",
      "aria-label": "Input field for certificate number (not required)",
      onChange: (e: any) =>
        onFieldChange("certificate_number", e.currentTarget.value),
    },
    className: formLineClassName,
  },
];
