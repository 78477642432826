import { Icons } from "@common/components";
import styles from "./MailModalHeader.module.scss";

export type MailModalHeaderProps = {
  onClose: () => void;
};

export const MailModalHeader: React.FC<MailModalHeaderProps> = ({
  onClose,
}) => {
  return (
    <div className={styles.root}>
      <button className={styles.root__button} onClick={onClose}>
        <Icons.Close className={styles.root__icon} />
      </button>
    </div>
  );
};
