import { EnvConfig } from "@common/configs/EnvConfig";
import { get } from "@common/networking";
import { IInstructorResponse } from "@instructors/interfaces";
import { IInstructorRequest } from "@instructors/interfaces/Instructor/IInstructorRequest";
import { castInstructorToInstructorEntity } from "@instructors/utils";

export const fetchInstructors = async (params: IInstructorRequest) => {
  try {
    const instructors = get<IInstructorResponse>(
      `${EnvConfig.ApiUrl}/instructors`,
      {
        ...(params as Record<string, string>),
      }
    );

    return instructors.then((instructors) =>
      instructors.data.map((instructor) =>
        castInstructorToInstructorEntity(instructor)
      )
    );
  } catch (e) {
    console.error(e);

    throw e;
  }
};
