import React from "react";
import styles from "./InstructorModalAbout.module.scss";

export type InstructorModalAboutProps = {
  descrition: string;
};

export const InstructorModalAbout: React.FC<InstructorModalAboutProps> = ({
  descrition,
}) => {
  return (
    <div className={styles.root}>
      <h2 className={styles.root__header}>About instructor</h2>
      <p
        className={styles.root__desc}
        dangerouslySetInnerHTML={{ __html: descrition }}
      />
    </div>
  );
};
