/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Flex, ScrollUpAffix } from "@common/components";
import { InstructorsGrid } from "@instructors/components";
import { useInstructorsContext } from "@instructors/context";
import { InstructorEntity, InstructorMapMark } from "@instructors/types";
import styles from "./Main.module.scss";
import { InstructorLayout } from "../InstructorLayout";

export type MainProps = {
  mapMarks: InstructorMapMark[];
  instructors: InstructorEntity[];
};

export const Main: React.FC<MainProps> = ({
  mapMarks: serverSideMapMarks,
  instructors: serverSideInstructors,
}) => {
  const { mapMarks, setMapMarks, setInstructors } = useInstructorsContext();

  const { ref: refForAffix, inView: inViewForAffix } = useInView();

  const onScrollUpClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setInstructors(serverSideInstructors);
    setMapMarks(serverSideMapMarks);
  }, []);

  return (
    <Flex className={styles.root} direction="column">
      <InstructorLayout mapMarks={mapMarks} onMarkerClickAction="filter">
        <InstructorsGrid ref={refForAffix} />
        <ScrollUpAffix isVisible={inViewForAffix} onClick={onScrollUpClick} />
      </InstructorLayout>
    </Flex>
  );
};
