import React from "react";
import styles from "./InstructorCardMore.module.scss";

export type InstructorCardMoreProps = {
  onClick: () => void;
};

export const InstructorCardMore: React.FC<InstructorCardMoreProps> = () => {
  return (
    <div className={styles.root}>
      <p className={styles.root__text}>MORE INFO</p>
    </div>
  );
};
