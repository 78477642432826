import Yup from "yup";
import { Form, FormOptions } from "@common/components";
import { concatClassNames } from "@common/utils";
import { useValidationForm } from "@common/hooks";
import { sendMail } from "@instructors/network";
import { getMailFormFields } from "./getMailFormFields";
import styles from "./MailModalMain.module.scss";
import { validationSchema } from "./validationSchema";
import { useState } from "react";

export type MailModalMainProps = {
  id: number;
  onClose: () => void;
};

export const MailModalMain: React.FC<MailModalMainProps> = ({
  id,
  onClose,
}) => {
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { onFieldChange, onSubmit, isSubmitAvailable, validateField, errors } =
    useValidationForm({
      initialState: {
        name: "",
        email: "",
        phone: "",
        text: "",
      },
      schema: validationSchema,
      onSubmit: async (formState) => {
        try {
          const response = await sendMail({ id, data: { ...formState, id } });

          //@ts-expect-error ts can't understand different resolving
          if (response.error) {
            setSubmitError("Message not send, try again later");
          } else {
            onClose();
          }
        } catch (e) {
          setSubmitError(e as string);
        }
      },
      shouldCalculateIsSubmitAvailable: true,
    });

  const formFields = getMailFormFields({
    labelClassName: styles.line__label,
    errors,
    inputClassName: (type) =>
      type === "input" ? styles.input__input : styles.input__textarea,
    formLineClassName: (type) =>
      type === "input"
        ? styles.line
        : concatClassNames([styles.line, styles.line__textarea]),
    onFieldChange: (name, value) => onFieldChange(name, value),
    onBlur: (name) => validateField(name),
  });

  const formOptions: FormOptions = {
    title: {
      text: "Email",
      className: styles.form__title,
    },
    fields: formFields,
    submitButton: {
      title: "Submit",
      className: styles.form__submit,
      available: isSubmitAvailable,
      onSubmit,
    },
    submitError,
  };

  return (
    <div className={styles.root}>
      <Form formOptions={formOptions} />
    </div>
  );
};
