import { FCWithChildren } from "@common/types";
import styles from "./InstructorInactiveWrapper.module.scss";

export type InstructorInactiveWrapperProps = {
  height: number | string;
  width?: number | string;
  isActive: boolean | null;
  enabled: boolean | null;
};

export const InstructorInactiveWrapper: FCWithChildren<
  InstructorInactiveWrapperProps
> = ({ children, isActive, enabled, height, width }) => {
  if (isActive && enabled) {
    return <>{children}</>;
  }

  return (
    <div className={styles.root}>
      <p style={{ height, width }} className={styles.root__cover}>
        {enabled && 'CERTIFICATE EXPIRED'}
        {!enabled && 'Certification Incomplete'}
      </p>
      {children}
    </div>
  );
};
