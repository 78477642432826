import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { concatClassNames } from "@common/utils";
import { InstructorEntity } from "@instructors/types";
import { Flex } from "@common/components";
import {
  InstructorCardContacts,
  InstructorCardTag,
  InstructorCardAvatar,
  InstructorCardCourses,
  InstructorCardCredentials,
  InstructorCardCertification,
  InstructorInactiveWrapper,
  InstructorCardMore,
} from "./partials";
import styles from "./InstructorCard.module.scss";
import {
  defaultModalContextValue,
  useModalContext,
} from "@instructors/context";
import { useRouter } from "next/router";
import { usePathname } from "next/navigation";

export type InstructorCardProps = InstructorEntity & { className?: string };

export const InstructorCard = forwardRef<HTMLDivElement, InstructorCardProps>(
  (
    {
      fullName,
      cardTagType,
      ribbonType,
      coursesStatuses,
      certificateNumber,
      certificateDate,
      certificateExpireDate,
      location,
      address,
      company,
      className,
      avatarPath,
      isActive,
      id,
      description,
      links,
      cooridanates,
      enabled,
    },
    ref
  ) => {
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(
      null
    );

    const { setModals } = useModalContext();
    const router = useRouter();
    const pathname = usePathname();

    useImperativeHandle(ref, () => containerRef!);

    return (
      <Flex
        ref={setContainerRef}
        className={concatClassNames([styles.root, className])}
        direction="column"
      >
        <InstructorInactiveWrapper
          height={containerRef?.clientHeight!}
          isActive={isActive}
          enabled={enabled}
        >
          <Flex
            className={styles.interactive}
            direction="column"
            onClick={() =>
              certificateNumber &&
              router.push(`${pathname}/${certificateNumber}`)
            }
          >
            <InstructorCardTag tagType={cardTagType} />

            <Flex>
              <InstructorCardAvatar
                imgSrc={avatarPath}
                ribbonType={ribbonType}
                title={fullName}
              />

              <div className={styles.main}>
                <InstructorCardCredentials name={fullName} />
                <InstructorCardCourses coursesStatuses={coursesStatuses} />
              </div>
            </Flex>

            <InstructorCardCertification
              certificateNumber={certificateNumber}
              certificateDate={certificateDate}
              certificateExpireDate={certificateExpireDate}
              company={company}
              location={location}
            />

            <InstructorCardContacts
              address={address}
              coordinates={cooridanates}
              location={location}
              onMailClick={(e) => {
                setModals({
                  ...defaultModalContextValue,
                  mailModal: { isVisible: true, id: id as number },
                });

                e.stopPropagation();
              }}
            />

            <InstructorCardMore onClick={() => { }} />
          </Flex>
        </InstructorInactiveWrapper>
      </Flex>
    );
  }
);

InstructorCard.displayName = "InstructorCard";
