import { MouseEventHandler, useMemo } from "react";
import { Collapse } from "@common/components";
import { useBreakpoints } from "@common/hooks";
import { Breakpoint } from "@common/types";
import styles from "./RegionList.module.scss";

export type RegionListProps = {
  regions: {
    title: string;
    onClick: MouseEventHandler<HTMLLIElement>;
  }[];
};

export const RegionList: React.FC<RegionListProps> = ({ regions }) => {
  const breakpoint = useBreakpoints();

  const regionsElements = useMemo(
    () =>
      regions.map(({ title, onClick }) => (
        <li key={title} className={styles.root__item} onClick={onClick}>
          {title}
        </li>
      )),
    [regions]
  );

  if (breakpoint >= Breakpoint.MD) {
    return <ul className={styles.root}>{regionsElements}</ul>;
  }

  return (
    <Collapse title="click to expand">
      <ul className={styles.root}>{regionsElements}</ul>
    </Collapse>
  );
};
