/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import { Flex } from "@common/components";
import { ImagesPaths } from "@common/configs/ImageConfig";
import { concatClassNames } from "@common/utils";
import styles from "./InstructorModalCourses.module.scss";

export type InstructorModalCoursesProps = {
  coursesStatuses: {
    1: boolean;
    2: boolean;
    3: boolean;
    4: boolean;
    5: boolean;
  } | null;
};

const courses: {
  course: 1 | 2 | 3 | 4 | 5;
  alt: string;
  src: string;
}[] = [
  {
    course: 1,
    src: ImagesPaths.courses[1],
    alt: "course 1 image",
  },
  {
    course: 2,
    src: ImagesPaths.courses[2],
    alt: "course 2 image",
  },
  {
    course: 3,
    src: ImagesPaths.courses[3],
    alt: "course 3 image",
  },
  {
    course: 4,
    src: ImagesPaths.courses[4],
    alt: "course 4 image",
  },
  {
    course: 5,
    src: ImagesPaths.courses[5],
    alt: "course 5 image",
  },
];

export const InstructorModalCourses: React.FC<InstructorModalCoursesProps> = ({
  coursesStatuses,
}) => {
  return (
    <Flex className={styles.root} direction="column">
      <Image
        className={styles.root__header}
        src={ImagesPaths.courses.header}
        alt={"course header image"}
        width={301}
        height={50}
        priority
      />
      <Flex className={styles.courses} direction="row">
        {courses.map(({ course, src, alt }) => (
          <Image
            key={alt}
            src={src}
            alt={alt}
            width={78}
            height={78}
            className={concatClassNames([
              styles.courses__course,
              coursesStatuses && coursesStatuses[course]
                ? ""
                : styles.courses__inactive,
            ])}
          />
        ))}
      </Flex>
    </Flex>
  );
};
