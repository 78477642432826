import { get } from "@common/networking";
import { EnvConfig } from "@common/configs/EnvConfig";
import { InstructorMapMark } from "@instructors/types";

type Lat = string;
type Lng = string;
type CertNumber = string;

export const fetchMapMarks = async (): Promise<InstructorMapMark[]> => {
  try {
    const mapMarks = get<Record<`${number}`, [Lat, Lng, CertNumber]>>(
      `${EnvConfig.MapMarksUrl}`
    );

    return mapMarks.then((instructorsResponse) => {
      const mapMarks = Object.values(instructorsResponse).map((mapMark) => {
        return {
          lat: Number(mapMark[0]),
          lng: Number(mapMark[1]),
          certNumber: Number(mapMark[2]),
        };
      });

      return mapMarks;
    });
  } catch (e) {
    console.error(e);

    throw e;
  }
};
