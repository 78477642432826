import { Flex } from "@common/components";
import styles from "./InstructorCardCredentials.module.scss";

export type InstructorCardCredentialsProps = {
  name: string;
};

export const InstructorCardCredentials: React.FC<
  InstructorCardCredentialsProps
> = ({ name }) => {
  return (
    <Flex direction="column">
      <h2 className={styles.root__name}>{name}</h2>
      <h3 className={styles.root__info}>Certified Barre Instructor</h3>
    </Flex>
  );
};
