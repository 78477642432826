import { EnvConfig } from "@common/configs/EnvConfig";
import { IResponse } from "@common/interfaces";
import { post } from "@common/networking";

type SendMailData = {
  name: string;
  id: number;
  email: string;
  phone?: string;
  text: string;
};

type SendMailProps = {
  data: SendMailData;
  id: number;
};

export const sendMail = async ({ id, data }: SendMailProps) => {
  try {
    const formData = new FormData();

    Object.entries(data).forEach((field) =>
      formData.append(field[0], String(field[1]))
    );

    const response = await post<IResponse<[]>, FormData>(
      `${EnvConfig.BaseUrl}/instructors/mail/${id}`,
      formData,
      { isJSON: false, onError: true }
    );

    return response;
  } catch (e) {
    throw e;
  }
};
