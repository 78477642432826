import { Icons } from "@common/components";
import styles from "./InstructorMapMarker.module.scss";

export type InstructorMapMarkerProps = {
  lat: number;
  lng: number;
  cluster?: {
    amount: number;
  };
  onClick?: () => void;
};

export const InstructorMapMarker: React.FC<InstructorMapMarkerProps> = ({
  cluster,
  onClick,
}) => {
  if (cluster) {
    return (
      <div
        className={styles.cluster}
        style={{
          width: `${10 + cluster.amount / 2}px`,
          height: `${10 + cluster.amount / 2}px`,
        }}
      >
        {cluster.amount}
      </div>
    );
  }

  return (
    <Icons.MapMark className={styles.root} onClick={onClick}></Icons.MapMark>
  );
};
