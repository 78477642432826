import { MouseEventHandler } from "react";
import { Flex, ImageButton } from "@common/components";
import { ImagesPaths } from "@common/configs/ImageConfig";
import { ImageAnchor } from "@common/components";
import styles from "./InstructorCardContacts.module.scss";

type Coordinates = {
  lat: string | null;
  lng: string | null;
};

export type InstructorCardContactsProps = {
  address: string | null;
  coordinates: Coordinates | null;
  location: string;
  onMailClick: MouseEventHandler<HTMLButtonElement>;
};

export const InstructorCardContacts: React.FC<InstructorCardContactsProps> = ({
  address,
  coordinates,
  location,
  onMailClick,
}) => {
  return (
    <Flex className={styles.root}>
      <ImageButton
        className={styles.root__button}
        src={ImagesPaths.mail}
        alt={"mail icon"}
        width={51}
        height={51}
        onClick={onMailClick}
      />
      <ImageAnchor
        className={styles.root__anchor}
        href={`https://maps.google.com/maps?${
          coordinates && coordinates.lat && coordinates.lng
            ? `q=${coordinates.lat},${coordinates.lng}`
            : `daddr=${address ? `${address},${location}` : `${location}`}`
        }`}
        target="_blank"
        src={ImagesPaths.location}
        alt={"location icon"}
        width={51}
        height={51}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Flex>
  );
};
