import { forwardRef } from "react";
import { useInView } from "react-intersection-observer";
import { Flex } from "@common/components";
import { InstructorCard } from "@instructors/components";
import { useInstructorsContext } from "@instructors/context";
import { fetchInstructors } from "@instructors/network";
import { CommonConfig } from "@common/configs/CommonConfig";
import styles from "./InstructorsGrid.module.scss";

export type InstructorsGridProps = {};

export const InstructorsGrid = forwardRef<HTMLDivElement, InstructorsGridProps>(
  ({ }, externalRef) => {
    const { instructors, setInstructors, additionalInfo, setAdditionalInfo } =
      useInstructorsContext();

    const { ref: refForInstructorLoading } =
      useInView({
        threshold: 0.4,
        onChange: async (inView) => {
          if (!additionalInfo.isAdditionByScrollBlocked && inView) {
            const limit = `${additionalInfo.showPerPage}`;
            const skip = `${additionalInfo.showPerPage * additionalInfo.page}`;

            const newInstructors = await fetchInstructors({
              enabled: "1",
              order_value: CommonConfig.tldRegion,
              limit,
              skip,
            });

            setAdditionalInfo({
              ...additionalInfo,
              page: additionalInfo.page + 1,
            });
            setInstructors([...instructors, ...newInstructors]);
          }
        },
      });

    return (
      <Flex ref={externalRef} className={styles.root}>
        {instructors.map((instructor, index) => (
          <InstructorCard
            ref={
              index === instructors.length - 5
                ? refForInstructorLoading
                : undefined
            }
            key={instructor.id + instructor.fullName}
            className={styles.root__card}
            {...instructor}
          />
        ))}
      </Flex>
    );
  }
);

InstructorsGrid.displayName = "InstructorsGrid";
