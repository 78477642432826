import { Form, FormOptions } from "@common/components";
import { useClassNameMemo, useForm } from "@common/hooks";
import {
  defaultModalContextValue,
  useInstructorsContext,
  useModalContext,
} from "@instructors/context";
import { fetchInstructors } from "@instructors/network";
import { getSearchFormFields } from "./getSearchFormFields";
import styles from "./SearchForm.module.scss";
import { useRouter } from "next/navigation";

export type SearchForm = {
  className?: string;
  onMarkerClickAction: "navigate" | "filter";
};

export const SearchForm: React.FC<SearchForm> = ({
  className,
  onMarkerClickAction,
}) => {
  const formClassName = useClassNameMemo([styles.root, className]);
  const router = useRouter();

  const { setInstructors, additionalInfo, setAdditionalInfo } =
    useInstructorsContext();
  const { setModals } = useModalContext();

  const getInstructors = async (formState: Record<string, string>) => {
    const instructors = await fetchInstructors({
      ...formState,
      limit: "100",
    });

    if (instructors.length === 0) {
      setModals({
        ...defaultModalContextValue,
        messageModal: { isVisible: true, message: "No result!" },
      });
    }

    setAdditionalInfo({ ...additionalInfo, isAdditionByScrollBlocked: true });
    setInstructors(instructors);
  };

  const { onFieldChange, onSubmit } = useForm({
    initialState: {
      last_name: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      certificate_number: "",
    },
    submit: (formState) => {
      getInstructors(formState);
      onMarkerClickAction === "navigate" && router.push("/");
    },
  });

  const formFields = getSearchFormFields({
    labelClassName: styles.field__label,
    formLineClassName: styles.field,
    onFieldChange: (name, value) => onFieldChange(name, value),
  });

  const formOptions: FormOptions = {
    title: {
      text: "Search by Name/Location",
      className: styles.root__header,
    },
    fields: formFields,
    submitButton: {
      title: "SEARCH",
      onSubmit: () => {
        onSubmit();
      },
    },
  };

  return <Form className={formClassName} formOptions={formOptions} />;
};
