import { Modal } from "@common/components";
import { MailModalHeader, MailModalMain } from "./partials";
import styles from "./MailModal.module.scss";

export type MailModalProps = {
  id: number | null;
  onClose: (isSend: boolean) => void;
};

export const MailModal: React.FC<MailModalProps> = ({ id, onClose }) => {
  return (
    <Modal
      contentClassName={styles.root}
      backdrop={{ isVisible: true }}
      onClose={() => onClose(false)}
    >
      <MailModalHeader onClose={() => onClose(false)} />
      <MailModalMain id={id!} onClose={() => onClose(true)} />
    </Modal>
  );
};
