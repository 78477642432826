export const post = async <T, P extends BodyInit>(
  url: string,
  data: P,
  options: {
    isJSON: boolean;
    onError?: boolean;
  } = {
    isJSON: true,
  }
): Promise<T | { error: Response }> => {
  try {
    const request = await fetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "no-cors",
      },
      body: options.isJSON ? JSON.stringify(data) : data,
    });

    if (request.status >= 200 && request.status < 300) {
      return request.json() as Promise<T>;
    }

    if (options.onError && request.status >= 300) {
      return new Promise((resolve) => resolve({ error: request }));
    }

    throw request;
  } catch (e) {
    throw e;
  }
};
