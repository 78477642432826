import GoogleMap from "google-map-react";
import { useClassNameMemo, useSupercluster } from "@common/hooks";
import { forwardRef, useEffect, useState } from "react";
import { InstructorMapMarker } from "@instructors/components";
import { InstructorMapMark, InstructorGeoJSONPoint } from "@instructors/types";
import { castMarkersToPoints } from "./castMarkersToPoints";
import { Flex } from "@common/components";
import { EnvConfig } from "@common/configs/EnvConfig";
import styles from "./InstructorsMap.module.scss";
import { useRouter } from "next/navigation";

export type InstructorsMapProps = {
  center: { lat: number; lng: number };
  markers: InstructorMapMark[];
  className?: string;
  onMarkClick: (certNumber: number) => void;
  onMarkerClickAction: "navigate" | "filter";
};

const getPoints = async ({
  markers,
  setPoints,
}: {
  markers: InstructorMapMark[];
  setPoints: (points: InstructorGeoJSONPoint[]) => void;
}) => {
  let points: InstructorGeoJSONPoint[] = await castMarkersToPoints(markers);

  setPoints(points);
};

export const InstructorsMap = forwardRef<HTMLDivElement, InstructorsMapProps>(
  ({ className, center, markers, onMarkClick, onMarkerClickAction }, ref) => {
    const [map, setMap] = useState<GoogleMap.ChangeEventValue | null>(null);
    const [points, setPoints] = useState<InstructorGeoJSONPoint[]>([]);
    const router = useRouter();

    const containerClassName = useClassNameMemo([styles.root, className]);

    const { clusters } = useSupercluster({
      points,
      zoom: map ? map.zoom : 4,
      bounds: map?.bounds && [
        map.bounds.nw.lng,
        map.bounds.se.lat,
        map.bounds.se.lng,
        map.bounds.nw.lat,
      ],
      options: { radius: 40, maxZoom: 10 },
    });

    useEffect(() => {
      getPoints({ markers, setPoints });
    }, [markers]);

    return (
      <Flex ref={ref} className={containerClassName}>
        <GoogleMap
          bootstrapURLKeys={{ key: EnvConfig.GMapApiKey as string }}
          center={center}
          defaultZoom={4}
          onChange={(map) => setMap(map)}
        >
          {clusters.map(({ properties, geometry }) => (
            <InstructorMapMarker
              key={
                properties.cluster === false
                  ? `${properties.certNumber}${properties.lat}`
                  : properties.cluster_id
              }
              lat={geometry.coordinates[1]}
              lng={geometry.coordinates[0]}
              cluster={
                properties.cluster
                  ? {
                      amount: properties.point_count,
                    }
                  : undefined
              }
              onClick={
                properties.cluster
                  ? undefined
                  : () => {
                      if (onMarkerClickAction === "navigate") {
                        router.push(`/${properties.certNumber}`);
                      } else if (onMarkerClickAction === "filter") {
                        onMarkClick(properties.certNumber);
                      }
                    }
              }
            />
          ))}
        </GoogleMap>
      </Flex>
    );
  }
);

InstructorsMap.displayName = "InstructorsMap";
