import { FormLineProps } from "@common/components";

enum FieldName {
  name = "name",
  email = "email",
  phone = "phone",
  text = "text",
}

type onFieldChange = (name: FieldName, value: string) => void;

export type getMailFormFields = ({
  labelClassName,
  inputClassName,
  formLineClassName,
  onFieldChange,
}: {
  labelClassName: string;
  errors: Record<FieldName, { isErrored: boolean; error: string | null }>;
  inputClassName: (inputType: "input" | "textarea") => string;
  formLineClassName: (inputType: "input" | "textarea") => string;
  onFieldChange: onFieldChange;
  onBlur: (name: FieldName) => void;
}) => FormLineProps[];

export const getMailFormFields: getMailFormFields = ({
  labelClassName,
  errors,
  inputClassName,
  formLineClassName,
  onFieldChange,
  onBlur,
}) => [
  {
    labelOptions: { title: "Your Name", className: labelClassName },
    inputOptions: {
      name: "name",
      placeholder: "Enter Name",
      className: inputClassName("input"),
      validation: errors[FieldName.name],
      onChange: (e: any) =>
        onFieldChange(FieldName.name, e.currentTarget.value),
      onBlur: () => onBlur(FieldName.name),
    },
    className: formLineClassName("input"),
  },
  {
    labelOptions: { title: "Your Email", className: labelClassName },
    inputOptions: {
      name: "email",
      placeholder: "Enter Email",
      type: "email",
      className: inputClassName("input"),
      validation: errors[FieldName.email],
      onChange: (e: any) =>
        onFieldChange(FieldName.email, e.currentTarget.value),
      onBlur: () => onBlur(FieldName.email),
    },
    className: formLineClassName("input"),
  },
  {
    labelOptions: { title: "Your Phone", className: labelClassName },
    inputOptions: {
      name: "phone",
      className: inputClassName("input"),
      validation: errors[FieldName.phone],
      onChange: (e: any) =>
        onFieldChange(FieldName.phone, e.currentTarget.value),
      onBlur: () => onBlur(FieldName.phone),
    },
    className: formLineClassName("input"),
  },
  {
    labelOptions: { title: "Message", className: labelClassName },
    inputOptions: {
      name: "text",
      type: "textarea",
      validation: errors[FieldName.text],
      className: inputClassName("textarea"),
      onChange: (e: any) =>
        onFieldChange(FieldName.text, e.currentTarget.value),
      onBlur: () => onBlur(FieldName.text),
    },
    className: formLineClassName("textarea"),
  },
];
