import { useState } from "react";
import { Modal } from "@common/components";
import styles from "./PhoneNumberModal.module.scss";

export type MessageModalProps = {
  message: string;
  onClose: () => void;
};

export const MessageModal: React.FC<MessageModalProps> = ({
  message,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const onModalClose = () => {
    setIsVisible(false);

    setTimeout(() => onClose(), 500);
  };

  return (
    <Modal
      className={
        isVisible ? styles.modal__appearance : styles.modal__disappearance
      }
      contentClassName={styles.root}
      onClose={onModalClose}
    >
      <p className={styles.root__text}>{message}</p>

      <div>
        <button className={styles.root__button} onClick={onModalClose}>
          OK
        </button>
      </div>
    </Modal>
  );
};
