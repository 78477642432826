import { Region } from "@common/types";

type GetRegionList = ({
  mapTop,
  setMapCenter,
}: {
  mapTop: number;
  setMapCenter: ({ lat, lng }: { lat: number; lng: number }) => void;
}) => {
  title: string;
  onClick: () => void;
}[];

export const getRegionList: GetRegionList = ({ mapTop, setMapCenter }) => [
  {
    title: Region.NorthAmerica,
    onClick: () => {
      window.scrollTo({ top: mapTop, behavior: "smooth" });
      setMapCenter({ lat: 48.1667, lng: -100.1667 });
    },
  },
  {
    title: Region.LatinAmerica,
    onClick: () => {
      window.scrollTo({ top: mapTop, behavior: "smooth" });
      setMapCenter({ lat: -14.6048, lng: -59.0625 });
    },
  },
  {
    title: Region.Europe,
    onClick: () => {
      window.scrollTo({ top: mapTop, behavior: "smooth" });
      setMapCenter({ lat: 51.34434, lng: 17.57813 });
    },
  },
  {
    title: Region.MiddleEastAfrica,
    onClick: () => {
      window.scrollTo({ top: mapTop, behavior: "smooth" });
      setMapCenter({ lat: 9.27562, lng: 29.35547 });
    },
  },
  {
    title: Region.AsiaPacific,
    onClick: () => {
      window.scrollTo({ top: mapTop, behavior: "smooth" });
      setMapCenter({ lat: 6.66461, lng: 123.39844 });
    },
  },
];
