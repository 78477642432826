import styles from "./InstructorPageCard.module.scss";
import { Flex } from "@common/components";
import { concatClassNames } from "@common/utils";
import { useRouter } from "next/router";
import {
  InstructorInactiveWrapper,
  InstructorCardContacts,
} from "@instructors/components/molecules/InstructorCard/partials";
import {
  defaultModalContextValue,
  useModalContext,
} from "@instructors/context";
import {
  InstructorModalTag,
  InstructorModalAvatar,
  InstructorModalCredentials,
  InstructorModalCourses,
  InstructorModalAbout,
  InstructorModalCertification,
  InstructorModalContacts,
} from "../InstructorModal/partials";
import { InstructorEntity } from "@instructors/types";

interface IInstructorPageCardProps {
  instructor: InstructorEntity;
  isInstructorPage?: boolean;
}

export const InstructorPageCard = ({
  instructor,
  isInstructorPage = false,
}: IInstructorPageCardProps) => {
  const { setModals } = useModalContext();
  const router = useRouter();
  if (!instructor) {
    return null;
  }

  return (
    <Flex
      className={concatClassNames([
        styles.root,
        styles.root__card,
        !isInstructorPage ? styles.isInstructorPage : "",
      ])}
      direction="column"
      onClick={() =>
        !isInstructorPage &&
        instructor &&
        router.push(`/${instructor.certificateNumber}`)
      }
    >
      <InstructorInactiveWrapper height="100%" isActive={instructor.isActive} enabled={instructor.enabled}>
        <InstructorModalTag tagType={instructor.cardTagType} />

        <Flex className={styles.info}>
          <InstructorModalAvatar
            imgSrc={instructor.avatarPath}
            ribbonType={instructor.ribbonType}
          />

          <div className={styles.main}>
            <InstructorModalCredentials name={instructor.fullName} />
            <InstructorModalCourses
              coursesStatuses={instructor.coursesStatuses}
            />
          </div>
        </Flex>

        <InstructorModalAbout
          descrition={
            instructor.isActive
              ? instructor?.description
                ? instructor.description
                : "Has no description"
              : "Certificate Expired"
          }
        />

        <InstructorModalCertification
          certificateNumber={instructor.certificateNumber}
          certificateDate={instructor.certificateDate}
          certificateExpireDate={instructor.certificateExpireDate}
          company={instructor.company}
          location={instructor.location}
        />

        <InstructorCardContacts
          address={instructor.address}
          coordinates={instructor.cooridanates}
          location={instructor.location}
          onMailClick={(e) => {
            setModals({
              ...defaultModalContextValue,
              mailModal: { isVisible: true, id: instructor.id as number },
            });

            e.stopPropagation();
          }}
        />

        {instructor?.links && <InstructorModalContacts {...instructor.links} />}
      </InstructorInactiveWrapper>
    </Flex>
  );
};
