import { InstructorGeoJSONPoint, InstructorMapMark } from "@instructors/types";

export const castMarkersToPoints = async (
  markers: InstructorMapMark[]
): Promise<InstructorGeoJSONPoint[]> =>
  await markers.map((marker) => ({
    type: "Feature",
    properties: {
      cluster: false,
      certNumber: marker.certNumber,
      lat: marker.lat,
      lng: marker.lng,
    },
    geometry: {
      type: "Point",
      coordinates: [marker.lng, marker.lat],
    },
  }));
