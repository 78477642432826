import { Flex } from "@common/components";
import { CommonConfig } from "@common/configs/CommonConfig";
import { MapConfig } from "@common/configs/MapConfig";
import { RegionList } from "@instructors/components/molecules";
import { getRegionList } from "@instructors/components/organisms/Main/getRegionList";
import { useInstructorsContext } from "@instructors/context";
import { fetchInstructors } from "@instructors/network";
import { InstructorMapMark } from "@instructors/types";
import { useRef, type ReactNode, useState } from "react";
import { InstructorsMap } from "../InstructorsMap";
import { SearchForm } from "../SearchForm";
import styles from "./InstructorLayout.module.scss";
import { concatClassNames } from "@common/utils";

interface InstructorsProps {
  children: ReactNode;
  mapMarks: InstructorMapMark[];
  onMarkerClickAction: "navigate" | "filter";
}

export const InstructorLayout = ({
  mapMarks,
  onMarkerClickAction,
  children,
}: InstructorsProps) => {
  const [mapCenter, setMapCenter] = useState(MapConfig.defaultMapCenter);
  const { setInstructors } = useInstructorsContext();
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  const regions = getRegionList({
    mapTop: mapContainerRef.current?.offsetTop ?? 0,
    setMapCenter,
  });

  const onMarkClick = async (certNumber: number) => {
    const instructors = await fetchInstructors({
      order_value: CommonConfig.tldRegion,
      order: "site_code",
      limit: "100",
      certificate_number: `${certNumber}`,
    });

    setInstructors(instructors);
  };

  return (
    <div
      className={concatClassNames([
        styles.root,
        onMarkerClickAction === "navigate" ? styles.navigate : null,
      ])}
    >
      <div>
        <RegionList regions={regions} />

        <Flex ref={mapContainerRef} className={styles.search}>
          <InstructorsMap
            className={styles.search__map}
            center={mapCenter}
            markers={mapMarks}
            onMarkClick={onMarkClick}
            onMarkerClickAction={onMarkerClickAction}
          />
          <SearchForm
            className={styles.search__form}
            onMarkerClickAction={onMarkerClickAction}
          />
        </Flex>
      </div>

      <div>{children}</div>
    </div>
  );
};
