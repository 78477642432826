/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import { ImagesPaths } from "@common/configs/ImageConfig";
import { CertTypes } from "@instructors/types";
import styles from "./InstructorModalAvatar.module.scss";
import { useRef } from "react";

export type InstructorModalAvatarProps = {
  imgSrc: string | null;
  ribbonType: CertTypes | null;
};

export const InstructorModalAvatar: React.FC<InstructorModalAvatarProps> = ({
  imgSrc,
  ribbonType,
}) => {
  const imageRef = useRef<HTMLImageElement | null>(null);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Image
          ref={imageRef}
          className={styles.root__avatar}
          src={imgSrc ?? ImagesPaths.avatarPlaceholder}
          alt={"avatar"}
          placeholder="blur"
          sizes="100%"
          style={{ maxHeight: imageRef.current?.clientWidth }}
          blurDataURL={ImagesPaths.avatarPlaceholder}
          fill={true}
        />
        {Boolean(ribbonType) &&
          ribbonType !== CertTypes.Essentials &&
          ribbonType !== CertTypes.NotAnnouced &&
          Object.keys(ImagesPaths.ribbon).includes(ribbonType!) && (
            <img
              className={styles.root__ribbon}
              src={ImagesPaths.ribbon[ribbonType!]}
              alt={"ribbon"}
            />
          )}
      </div>
    </div>
  );
};
