import React from "react";
import { Icons } from "@common/components";
import styles from "./InstructorModalClose.module.scss";

export type InstructorModalCloseProps = {
  onClose: () => void;
};

export const InstructorModalClose: React.FC<InstructorModalCloseProps> = ({
  onClose,
}) => {
  return <Icons.Close className={styles.root} onClick={onClose} />;
};
