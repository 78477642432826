import { Flex, IconAnchor, Icons } from "@common/components";
import styles from "./InstructorModalContacts.module.scss";

export type InstructorModalContactsProps = {
  linkedin?: string | null;
  instagram?: string | null;
  tik_tok?: string | null;
  telegram?: string | null;
  whatsapp?: string | null;
};

export const InstructorModalContacts: React.FC<
  InstructorModalContactsProps
> = ({ linkedin, instagram, tik_tok, telegram, whatsapp }) => {
  return (
    <Flex className={styles.root}>
      {linkedin && (
        <IconAnchor
          className={styles.anchor}
          href={linkedin}
          icon={<Icons.LinkedIn className={styles.anchor__icon} />}
        />
      )}

      {instagram && (
        <IconAnchor
          className={styles.anchor}
          href={instagram}
          icon={<Icons.Instagram className={styles.anchor__icon} />}
        />
      )}

      {tik_tok && (
        <IconAnchor
          className={styles.anchor}
          href={tik_tok}
          icon={<Icons.TikTok className={styles.anchor__icon} />}
        />
      )}

      {telegram && (
        <IconAnchor
          className={styles.anchor}
          href={telegram}
          icon={<Icons.Telegram className={styles.anchor__icon} />}
        />
      )}
      {whatsapp && (
        <IconAnchor
          className={styles.anchor}
          href={whatsapp}
          icon={<Icons.Whatsapp className={styles.anchor__icon} />}
        />
      )}
    </Flex>
  );
};
